<template>

  <div>
    <dealers-list-add-new
      :is-add-new-dealer-sidebar-active.sync="isAddNewDealerSidebarActive"
      @refetch-data="fetchData"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Pokaż</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>dealerów</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Szukaj"
              />
              <b-button
                v-if="isAdmin"
                variant="gradient-primary"
                @click="isAddNewDealerSidebarActive = true"
              >
                <span class="text-nowrap">Dodaj dealera</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refDealerListTable"
        class="position-relative"
        :items="dealersList"
        responsive
        :fields="visibleTableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="Nie znaleziono dealerów spełniających podane kryteria."
        :sort-desc.sync="isSortDirDesc"
        :busy="loading"
        :per-page="perPage"
        :current-page="currentPage"
      >
        <template
          v-if="!dealersList.length"
          #table-busy
        >
          <div class="text-center">
            <b-spinner class="align-middle mr-1" /> Pobieranie listy dealerów
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div style="white-space: nowrap">
            <b-button-group>
              <b-button
                size="sm"
                variant="light"
                :to="'/dealers/edit/' + data.item.id"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Edytuj</span>
              </b-button>

              <b-button
                size="sm"
                variant="light"
                @click="dealerToDelete = data.item"
              >
                <feather-icon icon="Trash2Icon" />
                <span class="align-middle ml-50">Usuń</span>
              </b-button>
            </b-button-group>
          </div>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Widok {{ metadata.from }} - {{ metadata.to }} z {{ metadata.of }} dealerów</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalDealers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
    <template v-if="dealerToDelete">
      <b-modal
        v-if="!dealerToDeleteHasUsers"
        v-model="deleteModalOpen"
        centered
        modal-class="modal-danger"
        title="Potwierdź usunięcie"
        ok-variant="danger"
        cancel-variant="light"
        ok-title="Usuń"
        cancel-title="Anuluj"
        @ok="deleteDealer"
      >
        Na pewno usunąć dealera <strong>{{ dealerToDelete.name }} (nr {{ dealerToDelete.dealerNo }})
        </strong>?</b-modal>
      <b-modal
        v-if="dealerToDeleteHasUsers"
        v-model="deleteModalOpen"
        centered
        modal-class="modal-warning"
        title="Uwaga"
        ok-only
        ok-variant="light"
        ok-title="OK"
        @ok="() => { dealerToDelete = null }"
      >
        Nie możesz usunąć dealera <strong>{{ dealerToDelete.name }} (nr {{ dealerToDelete.dealerNo }})
        </strong>ponieważ jest on powiązany z pracownikiem.</b-modal>
    </template>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable,
  BPagination, BButtonGroup, BModal, BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import visibleTableColumns from '@/helpers/visibleTableColumns'
import DealersListAddNew from './DealersListAddNew.vue'

export default {
  components: {
    DealersListAddNew,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,

    BPagination,
    BButtonGroup,
    BModal,
    BSpinner,

    vSelect,
  },

  setup() {
    const isAddNewDealerSidebarActive = ref(false)

    const tableColumns = [
      { key: 'name', sortable: true, label: 'Nazwa dealera' },
      { key: 'dealerNo', sortable: true, label: 'Numer dealera' },
      { key: 'nip', label: 'NIP' },
      { key: 'address.city', sortable: true, label: 'Miasto' },
      { key: 'employeesCount', sortable: true, label: 'Liczba Pracowników' },
      { key: 'managersCount', sortable: true, label: 'Liczba Managerów' },
      { key: 'actions', label: 'Akcje', visibleTo: ['ROLE_ADMIN'] },
    ]

    // Table Handlers
    const perPage = ref(10)
    const currentPage = ref(1)
    const perPageOptions = [10, 25, 50, 100]
    const searchQuery = ref('')
    const sortBy = ref('name')
    const isSortDirDesc = ref(false)
    const dealerFilter = ref(null)
    const departmentFilter = ref(null)
    const statusFilter = ref(null)

    return {

      // Sidebar
      isAddNewDealerSidebarActive,

      tableColumns,
      perPage,
      currentPage,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,

      // Extra Filters
      dealerFilter,
      departmentFilter,
      statusFilter,
    }
  },
  data() {
    return {
      dealerToDelete: null,
      loading: false,
    }
  },
  computed: {
    currentDealer() {
      return this.$store.state.auth.dealerData
    },

    dealersList() {
      let list = this.$store.state.dealer.list ? this.$store.state.dealer.list['hydra:member'] : []

      if (this.searchQuery) {
        const explodedQuery = this.searchQuery.toLowerCase().split(' ')
        let found = false

        list = list.filter(item => {
          found = false
          Object.values(item).forEach(value => {
            explodedQuery.forEach(query => {
              if (value && value.toString().toLowerCase().indexOf(query) !== -1) found = true
            })
          })
          return found
        })
      }

      if (this.statusFilter) {
        list = list.filter(item => item.status === this.statusFilter)
      }

      if (this.dealerFilter) {
        list = list.filter(item => (item.dealer ? item.dealer['@id'] === this.dealerFilter : false))
      }

      if (this.departmentFilter) {
        list = list.filter(item => (item.departments.includes(this.departmentFilter)))
      }

      return list
    },

    dealerList() {
      return this.$store.state.dealer.list ? this.$store.state.dealer.list['hydra:member'] : []
    },

    visibleTableColumns,

    totalDealers() {
      return this.dealersList.length
    },

    metadata() {
      const localItemsCount = this.$refs.refDealerListTable ? this.$refs.refDealerListTable.localItems.length : 0
      return {
        from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + localItemsCount,
        of: this.totalDealers,
      }
    },

    deleteModalOpen: {
      get() {
        return !!this.dealerToDelete
      },
      set(value) {
        this.dealerToDelete = value
      },
    },

    dealerToDeleteHasUsers() {
      return this.dealerToDelete && (this.dealerToDelete.employeesCount || this.dealerToDelete.managersCount)
    },
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.loading = true
      this.$store.dispatch('dealer/fetchList').finally(() => { this.loading = false })
    },
    deleteDealer() {
      this.$store.dispatch('dealer/deleteDealer', { id: this.dealerToDelete.id }).then(response => {
        if (response && response.status === 204) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Dealer został usunięty',
              icon: 'DealerXIcon',
              variant: 'success',
            },
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Nie udało się usunąć dealera.',
              text: response ? response.data['hydra:description'] : null,
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
            timeout: false,
          })
        }
        this.$store.dispatch('dealer/fetchList')
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
