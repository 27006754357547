<template>
  <b-sidebar
    id="add-new-dealer-sidebar"
    :visible="isAddNewDealerSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-dealer-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        "
      >
        <h5 class="mb-0">
          Dodaj dealera
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <h2>Dane podstawowe</h2>
          <hr>
          <!-- Nazwa dealera -->
          <validation-provider
            #default="validationContext"
            name="Nazwa dealera"
            rules="required"
            autofocus
          >
            <b-form-group
              label="Nazwa dealera"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="dealerData.name"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Numer dealera -->
          <validation-provider
            #default="validationContext"
            name="Numer dealera"
            rules="required"
          >
            <b-form-group
              label="Numer dealera"
              label-for="dealerNo"
            >
              <b-form-input
                id="dealerNo"
                v-model="dealerData.dealerNo"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- NIP -->
          <validation-provider
            #default="validationContext"
            name="NIP"
            rules="required|nip|length:10"
            :custom-messages="{nip:'Nieprawidłowy numer NIP.', length: 'Wprowadź NIP bez myślników.'}"
          >
            <b-form-group
              label="NIP"
              label-for="nip"
            >
              <b-form-input
                id="nip"
                v-model="dealerData.nip"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Ulica -->
          <validation-provider
            #default="validationContext"
            name="Ulica"
            rules="required"
          >
            <b-form-group
              label="Ulica"
              label-for="address.street"
            >
              <b-form-input
                id="address.street"
                v-model="dealerData.address.street"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Nr domu / nr lokalu -->
          <validation-provider
            #default="validationContext"
            name="Nr domu / nr lokalu"
            rules="required"
          >
            <b-form-group
              label="Nr domu / nr lokalu"
              label-for="address.streetNo"
            >
              <b-form-input
                id="address.streetNo"
                v-model="dealerData.address.streetNo"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Kod pocztowy -->
          <validation-provider
            #default="validationContext"
            name="Kod pocztowy"
            rules="required|regex:^\d{2}-\d{3}$"
            :custom-messages="{regex: 'Nieprawidłowy format kodu pocztowego'}"
          >
            <b-form-group
              label="Kod pocztowy"
              label-for="address.postCode"
            >
              <b-form-input
                id="address.postCode"
                v-model="dealerData.address.postCode"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Miasto -->
          <validation-provider
            #default="validationContext"
            name="Miasto"
            rules="required"
          >
            <b-form-group
              label="Miasto"
              label-for="address.city"
            >
              <b-form-input
                id="address.city"
                v-model="dealerData.address.city"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <h2 class="mt-2">
            Dane do faktury
          </h2>
          <hr>

          <b-form-checkbox
            v-model="sameInvoiceData"
            class="mb-2"
          >
            Takie same jak w danych podstawowych
          </b-form-checkbox>
          <template v-if="!sameInvoiceData">

            <validation-provider
              #default="validationContext"
              name="Nazwa"
              rules="required"
            >
              <b-form-group
                label="Nazwa"
                label-for="invoiceData.name"
              >
                <b-form-input
                  id="invoiceData.name"
                  v-model="dealerData.invoiceData.name"
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="validationContext"
              name="NIP"
              rules="required|nip|length:10"
              :custom-messages="{nip:'Nieprawidłowy numer NIP.', length: 'Wprowadź NIP bez myślników.'}"
            >
              <b-form-group
                label="NIP"
                label-for="invoiceData.nip"
              >
                <b-form-input
                  id="invoiceData.nip"
                  v-model="dealerData.invoiceData.nip"
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="validationContext"
              name="Ulica"
              rules="required"
            >
              <b-form-group
                label="Ulica"
                label-for="invoiceData.street"
              >
                <b-form-input
                  id="invoiceData.street"
                  v-model="dealerData.invoiceData.street"
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Nr domu / nr lokalu -->
            <validation-provider
              #default="validationContext"
              name="Nr domu / nr lokalu"
              rules="required"
            >
              <b-form-group
                label="Nr domu / nr lokalu"
                label-for="invoiceData.streetNo"
              >
                <b-form-input
                  id="invoiceData.streetNo"
                  v-model="dealerData.invoiceData.streetNo"
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Kod pocztowy -->
            <validation-provider
              #default="validationContext"
              name="Kod pocztowy"
              rules="required|regex:^\d{2}-\d{3}$"
              :custom-messages="{regex: 'Nieprawidłowy format kodu pocztowego'}"
            >
              <b-form-group
                label="Kod pocztowy"
                label-for="invoiceData.postCode"
              >
                <b-form-input
                  id="invoiceData.postCode"
                  v-model="dealerData.invoiceData.postCode"
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- Miasto -->
            <validation-provider
              #default="validationContext"
              name="Miasto"
              rules="required"
            >
              <b-form-group
                label="Miasto"
                label-for="invoiceData.city"
              >
                <b-form-input
                  id="invoiceData.city"
                  v-model="dealerData.invoiceData.city"
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </template>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="loading"
            >
              Dodaj
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Anuluj
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormCheckbox,

} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, numeric, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
// import countries from '@/@fake-db/data/other/countries'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormCheckbox,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewDealerSidebarActive',
    event: 'update:is-add-new-dealer-sidebar-active',
  },
  props: {
    isAddNewDealerSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      numeric,
      email,
      loading: false,
      sameInvoiceData: true,
    }
  },
  setup() {
    const blankDealerData = {
      name: '',
      dealerNo: '',
      nip: '',
      address: {
        city: '',
        street: '',
        streetNo: '',
        postCode: '',
      },
      invoiceData: {
        name: '',
        city: '',
        street: '',
        streetNo: '',
        postCode: '',
      },
    }

    const dealerData = ref(JSON.parse(JSON.stringify(blankDealerData)))
    const resetDealerData = () => {
      dealerData.value = JSON.parse(JSON.stringify(blankDealerData))
    }

    const { refFormObserver, getValidationState, resetForm } = formValidation(resetDealerData)

    return {
      dealerData,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
  computed: {
    currentDealer() {
      return this.$store.state.auth.dealerData
    },
  },

  methods: {
    onSubmit() {
      if (this.sameInvoiceData) {
        this.dealerData.invoiceData = {
          name: this.dealerData.name,
          nip: this.dealerData.nip,
          ...JSON.parse(JSON.stringify(this.dealerData.address)),
        }
      }

      const payload = { ...this.dealerData }

      this.loading = true
      store
        .dispatch('dealer/addDealer', payload)
        .then(response => {
          if (response && response.status === 201) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Dealer dodany',
                icon: 'DealerPlusIcon',
                variant: 'success',
              },
            })
            this.$emit('refetch-data')
            this.$emit('update:is-add-new-dealer-sidebar-active', false)
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Nie udało się dodać dealera.',
                text: response ? response.data['hydra:description'] : null,
                icon: 'AlertCircleIcon',
                variant: 'danger',
              },
            })
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-dealer-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
